import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import axios from 'axios';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import Layout from '../components/layout';
import SpinnerButton from '../components/SpinnerButton';
import InkblotLogo from '../images/nav/inkblot-logo.svg';
import SessionTwo from '../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../utils/environment';

class TwoFactor extends React.Component {
  constructor() {
    super();
    this.state = {
      emailResent: false,
      loading: false,
      error: false,
    };
  }

  resendEmail = () => {
    const { location } = this.props;
    const twoFactorId = get(location, 'state.twoFactorId');

    this.setState({ emailResent: false, loading: true, error: false });
    axios
      .get(`${SERVER_URL}/api/v2/two_factor_logs/${twoFactorId}`)
      .then(() => this.setState({ loading: false, emailResent: true }))
      .catch(() => this.setState({ loading: false, error: true }));
  };

  render() {
    const { emailResent, loading, error } = this.state;
    const { location } = this.props;
    const email = get(location, 'state.email');

    return (
      <Layout location={location}>
        <div className="two-factor" role="main">
          <div className="container">
            <Link to="/">
              <img alt="Home page" src={InkblotLogo} />
            </Link>
            <div className="text-image-container">
              <img alt="" className="left-image" src={SessionTwo} />
              <div className="text-container">
                <h2>
                  <FormattedMessage
                    id="two_factor.twoStep"
                    defaultMessage="Two-step Authentication"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="two_factor.additionalSecurity"
                    defaultMessage="As an additional security measure, an email with an
                  authentication link has been sent to "
                  />
                  <span
                    className="bold-text"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {email || 'your email'}
                  </span>
                  <FormattedMessage
                    id="two_factor.verifyIdentity"
                    defaultMessage=" to verify your identity. Please click on the link in the email
                  in order to access Inkblot Therapy."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="two_factor.emailMessage"
                    defaultMessage="If the email message does not arrive within 5 minutes, check
                  your spam folder or click the button below to send it again."
                  />
                </p>
                <SpinnerButton
                  label={
                    <FormattedMessage
                      id="two_factor.resendEmail"
                      defaultMessage="Resend Email"
                    />
                  }
                  loading={loading}
                  onClick={this.resendEmail}
                />
                {emailResent && (
                  <p
                    className="bold-text small-text"
                    style={{ marginTop: '20px' }}
                  >
                    <FormattedMessage
                      id="two_factor.emailSent"
                      defaultMessage="The email has been sent again."
                    />
                  </p>
                )}
                {error && (
                  <p className="error-text" style={{ marginTop: '20px' }}>
                    <FormattedMessage
                      id="two_factor.errorEmail"
                      defaultMessage="There was an error sending the email."
                    />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

TwoFactor.propTypes = {
  location: PropTypes.object.isRequired,
};

export default TwoFactor;
